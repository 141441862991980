import { useCartStore } from '~/stores/cart'
import { useFilialStore } from '~/stores/filial'

const enum MarkerColor {
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
}
interface State {
  stopList: IStopList['stop_list'] | {}
  canDelivery: boolean
  canPickup: boolean
  categories: never[]
  filials: IFilial[]
  categoryNames: string[]
  selectedCategoryNames: string[]
  organizations: never[]
  selectedOrganizations: string[]
  selectedFilials: IFilial[]
  loading: boolean
}

export const useStopListStore = defineStore({
  id: 'stopList',
  state: (): State => ({
    stopList: {},
    canDelivery: true,
    canPickup: false,
    categories: [],
    filials: [],
    categoryNames: [],
    selectedCategoryNames: [],
    organizations: [],
    selectedOrganizations: [],
    selectedFilials: [],
    loading: false,
  }),
  getters: {
    canPickupProduct(state) {
      return state.canPickup
    },
    filialIsOutOfStock() {
      return (filialId: string) => {
        return [MarkerColor.RED, MarkerColor.YELLOW].includes(
          this.filialMarker(filialId)
        )
      }
    },
    
    filialMarker(state) {
      return (filialId: number) => {
        const cartStore = useCartStore()
        const { ORDER_TYPE, SELECTED_DATE, SELECTED_TIME } = cartStore

        try {
          // Получаем stopList в зависимости от типа доставки
          let filialStopList
          if (cartStore.isExpressDelivery) {
            filialStopList = state.stopList?.express_delivery[filialId]
          } else {
            filialStopList = state.stopList[SELECTED_DATE]?.[ORDER_TYPE]?.[SELECTED_TIME]?.[filialId]
          }
    
          // Проверяем, если филиал не найден или отсутствует stopList
          if (!filialStopList) {
            return MarkerColor.GREEN
          }
    
          // Преобразуем stopList в массив значений
          const filialStopListArray = Object.values(filialStopList)
          const filialStopListLength = filialStopListArray.length
          const productCount = cartStore.products.length
    
          // Проверяем, если все товары недоступны
          const isOutOfStock = filialStopListArray.every((productClass) => productClass === MarkerColor.RED)
    
          if (filialStopListLength === productCount && isOutOfStock) {
            return MarkerColor.RED
          }
    
          // Проверяем, если хотя бы один товар недоступен
          const someIsOutOfStock = filialStopListArray.includes(MarkerColor.RED)
          if (someIsOutOfStock) {
            return MarkerColor.YELLOW
          }
        } catch (e) {
          console.error('Ошибка при вычислении filialMarker:', e)
        }
    
        // Если никаких проблем нет, возвращаем зелёный маркер
        return MarkerColor.GREEN
      };
    },
    
    productFilialMarker() {
      return (stopList, filialId) => {
        const cartStore = useCartStore()
        try {
          const filialStopList = stopList.find(
            (filial) => filial.id === filialId
          )
          if (!filialStopList) return MarkerColor.GREEN

          const filialStopListArray = Object.keys(filialStopList)?.map(
            (item) => filialStopList[item]
          )
          const filialStopListLength = filialStopListArray.length
          const productCount = cartStore.products.length
          const isOutOfStock = filialStopListArray.every((productClass) => {
            return productClass === MarkerColor.RED
          })

          if (filialStopListLength === productCount && isOutOfStock) {
            return MarkerColor.RED
          }
          const someIsOutOfStock = filialStopListArray.includes(MarkerColor.RED)
          if (someIsOutOfStock) {
            return MarkerColor.YELLOW
          }
        } catch (e) {
          console.error(e)
        }

        return MarkerColor.GREEN
      }
    },
    productIsOutOfStock(state) {
      return (productId: string | number) => {
        const filialStore = useFilialStore()
        const cartStore = useCartStore()
        const { ORDER_TYPE, SELECTED_DATE, SELECTED_TIME } = cartStore
        try {
          if (cartStore.isExpressDelivery) {
            return (
              state.stopList?.express_delivery?.[filialStore.activeFilial.id]?.[
                productId
              ] === MarkerColor.RED
            )
          } else {
            return (
              state.stopList[SELECTED_DATE]?.[ORDER_TYPE]?.[SELECTED_TIME]?.[
                filialStore.activeFilial.id
              ]?.[productId] === MarkerColor.RED
            )
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    categories(state) {
      const categories = JSON.parse(JSON.stringify(state.categories))
      return categories.filter((category) =>
        state.selectedCategoryNames.includes(category.name)
      )
    },
    filials(state) {
      const filials = []
      state.selectedOrganizations.map((organization) => {
        filials.push.apply(filials, state.organizations[organization])
      })
      state.filials = filials

      return filials
    },
    categoryNames(state) {
      return state.categoryNames
    },
    selectedCategoryNames(state) {
      return state.selectedCategoryNames
    },
    selectedFilials(state) {
      return state.selectedFilials.filter((element) =>
        state.filials.includes(element)
      )
    },
  },
  actions: {
    setStopList(stopList: IStopList['stop_list']) {
      this.stopList = stopList
    },
    setCanDelivery(canDelivery: boolean) {
      this.canDelivery = canDelivery
    },
    setCanPickUp(canPickup: boolean) {
      this.canPickup = canPickup
    },

    setSelectedFilial(filial) {
      const index = this.selectedFilials.indexOf(filial)
      if (index > -1) {
        this.selectedFilials.splice(index, 1)
      } else {
        this.selectedFilials.push(filial)
      }
    },
    setSelectedCategoryName(categoryName) {
      const index = this.selectedCategoryNames.indexOf(categoryName)
      if (index > -1) {
        this.selectedCategoryNames.splice(index, 1)
      } else {
        this.selectedCategoryNames.push(categoryName)
      }
    },
    setSelectedOrganization(organization) {
      const index = this.selectedOrganizations.indexOf(organization)
      if (index > -1) {
        this.selectedOrganizations.splice(index, 1)
      } else {
        this.selectedOrganizations.push(organization)
      }
    },
    setLoading(value: boolean) {
      this.loading = value
    },
    getCartStopList() {
      this.loading = true
      const cartStore = useCartStore()
      if (cartStore.cartId) {
        const city = JSON.parse(localStorage.getItem('city') || '{}')
        useApi()
          .$get<IStopList>(`cart/${cartStore.cartId}/stop_list`, {
            params: {
              city_id: city.id,
            },
          })
          .then((response) => {
            this.stopList = response.stop_list
            this.canDelivery = response.can_delivery
            this.canPickup = response.can_pickup
            cartStore.setPeriods(response)
            if (!response.can_delivery && response.can_pickup) {
              cartStore.setIsDelivery(response.can_delivery)
            }
          })
          .finally(() => (this.loading = false))
      }
    },
  },
})
